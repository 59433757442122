import { template as template_3850d1cceefd4ab881d3d5eef9cacb02 } from "@ember/template-compiler";
const WelcomeHeader = template_3850d1cceefd4ab881d3d5eef9cacb02(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
