import { template as template_185c99fb3c58415eb2000cbf8bc85c42 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_185c99fb3c58415eb2000cbf8bc85c42(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
