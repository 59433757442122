import { template as template_f182690fb4ca439db127ec17567c349a } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_f182690fb4ca439db127ec17567c349a(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
