import { template as template_be2e4385686f4005b7622ae3b5583cbf } from "@ember/template-compiler";
const FKControlMenuContainer = template_be2e4385686f4005b7622ae3b5583cbf(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
